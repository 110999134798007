@import url(https://fonts.googleapis.com/css2?family=Playball&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Quicksand:wght@500&display=swap);
:root {
  --color-special: #ff9110;
  --color-white: #fff;
  --color-white-600: #d5d5d5;
  --color-black: #000;
  --color-black-400: #2b2b2b;
  --color-gray-100: #f7f8fa;
  --color-gray-200: #f1f2f5;
  --color-gray-300: #e4e6eb;
  --color-gray-400: #bdbfc7;
  --color-gray-500: #8f94a2;
  --color-gray-600: #757a8a;
  --color-gray-650: #696e7c;
  --color-gray-700: #4f5461;
  --color-gray-800: #2c3242;
  --color-gray-900: #1b1f29;
  --color-blue-100: #ecf2ff;
  --color-blue-200: #d6e3ff;
  --color-blue-300: #bdd2ff;
  --color-blue-400: #175fff;
  --color-blue-500: #1043b2;
  --color-blue-transparent: rgba(236, 242, 255, 0.5);
  --color-violet-100: #7c3bff;
  --color-violet-400: #6930db;
  --color-violet-600: #6930db;
  --color-violet-700: #583979;
  --color-violet-800: #583979;
  --color-yellow-100: #fffbd8;
  --color-yellow-400: #f9cb40;
  --color-orange-100: #fffff0;
  --color-orange-400: #ffa201;
  --color-orange-600: #ff7a1a;
  --color-red-100: #fff2f2;
  --color-red-200: #ffd3d3;
  --color-red-300: #ffbaba;
  --color-red-400: #cd3636;
  --color-red-500: #ae1d1d;
  --color-green-100: #e2f8ed;
  --color-green-200: #c5f2dc;
  --color-green-400: #3dd48a;
  --color-green-500: #36ba79;
  --color-green-600: #1f6e48;
  --color-pink-100: #e7c1ff;
  --color-pink-400: #cb76ff;
  --color-primary-100: var(--color-blue-100);
  --color-primary-200: var(--color-blue-200);
  --color-primary-300: var(--color-blue-300);
  --color-primary-400: var(--color-blue-400);
  --color-primary-500: var(--color-blue-500);
  --color-text-dark: var(--color-gray-900);
  --color-text: var(--color-gray-800);
  --color-text-light: var(--color-gray-650);
  --color-text-disabled: var(--color-gray-400);
  --color-line: var(--color-gray-300);
  --color-line-light: var(--color-gray-200);
  --color-icon-fill: var(--color-gray-600);
  --color-button-primary-background: var(--color-primary-400);
  --color-button-primary-background-hover: var(--color-primary-500);
  --color-button-primary-text: var(--color-white);
  --color-button-primary-text-hover: var(--color-white);
  --color-button-secondary-text: var(--color-primary-400);
  --color-button-secondary-border: var(--color-gray-300);
  --color-button-secondary-background: var(--color-white);
  --color-button-secondary-text-hover: var(--color-primary-400);
  --color-button-secondary-background-hover: var(--color-primary-100);
  --color-button-text: var(--color-white);
  --color-button-text-hover: var(--color-white);
  --color-button-background: var(--color-primary-400);
  --color-button-background-hover: var(--color-primary-500);
  --color-menu-background-hover: var(--color-gray-200);
  --color-menu-item-highlight: var(--color-gray-300);
  --transition-duration: 0.2s;
  --transition-duration-long: 0.4s;
  --spacing-1: 0.0625rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-20: 1.25rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-128: 8rem;
  --spacing-160: 10rem;
  --page-padding: var(--spacing-24);
  --page-padding-small: var(--spacing-16);
  --border-radius-small: 0.25rem;
  --border-radius: 0.5rem;
  --border-radius-big: 0.75rem;
  --input-height: var(--spacing-48);
  --input-height-small: var(--spacing-40);
  --input-height-tiny: var(--spacing-32);
  --input-border-radius: var(--border-radius-small);
  --button-height-small: var(--input-height-small);
  --button-height: var(--input-height);
  --button-border-radius: var(--input-border-radius);
  --font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif;
  --font-family-display: "Larsseit", -apple-system, BlinkMacSystemFont,
    "Segoe UI", sans-serif;
  --font-size-smaller: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size: 0.875rem;
  --font-size-big: 1rem;
  --font-size-bigger: 1.125rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --font-size-heading-1: 2rem;
  --font-size-heading-2: 1.5rem;
  --font-size-heading-3: var(--font-size-bigger);
  --font-size-heading-4: var(--font-size-big);
  --line-height: 1.6;
  --line-height-tight: 1.25;
  --box-shadow: 0 0.0625rem 0.125rem rgba(44, 50, 66, 0.08);
  --box-shadow-long: 0 0 0.25rem rgba(44, 50, 66, 0.08),
    0 0.25rem 1rem rgba(44, 50, 66, 0.08);
  --box-shadow-panel: 0 0 0.125rem 0.0625rem rgba(44, 50, 66, 0.08);
  --box-shadow-error: 0rem 0.0625rem 0.125rem var(--color-red-300);
  --z-index-header: 10;
  --z-index-skip-nav: 20;
  --z-index-events-popup: 2147483004;
  --z-index-tickets-bar: 2147483024;
  --z-index-tickets: 2147483034;
  --z-index-modal: 2147483044;
  --avatar-size-tiny: 1.5rem;
  --avatar-size-small: 2rem;
  --avatar-size-medium: 2.5rem;
  --avatar-size-large: 3rem;
  --avatar-size-huge: 3.375rem;
  --avatar-size-gigantic: 4rem;
  --button-size-small: 1.5rem;
  --button-size-medium: 2rem;
  --button-size-large: 3rem;
  --dash-nav-width: 16rem;
  --dash-nav-width-v2: 15rem;
  --color-tooltip: var(--color-white);
  --color-tooltip-background: var(--color-black);
  --tooltip-tail-size: 0.625rem;
  --tooltip: 100;
}

.text-black {
  color: #000 !important;
}

.text-gray-6 {
  color: #757a8a;
  color: var(--color-gray-600);
}

.no-border {
  border: 0px solid #000;
  border: 0px solid var(--color-black);
}

.box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.lh-12 {
  line-height: 0.75rem;
  line-height: var(--spacing-12);
}

.lh-18 {
  line-height: var(--spacing-18);
}

.lh-24 {
  line-height: 1.5rem;
  line-height: var(--spacing-24);
}

.lh-32 {
  line-height: 2rem;
  line-height: var(--spacing-32);
}

.lh-48 {
  line-height: 3rem;
  line-height: var(--spacing-48);
}

.fs-10 {
  font-size: 10px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 1.5rem !important;
  font-size: var(--spacing-24) !important;
}

.fs-32 {
  font-size: 2rem !important;
  font-size: var(--spacing-32) !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.pt-12 {
  padding-top: 0.75rem !important;
  padding-top: var(--spacing-12) !important;
}

.pb-12 {
  padding-bottom: 0.75rem !important;
  padding-bottom: var(--spacing-12) !important;
}

.py-12 {
  padding-top: 0.75rem !important;
  padding-top: var(--spacing-12) !important;
  padding-bottom: 0.75rem !important;
  padding-bottom: var(--spacing-12) !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
  padding-top: var(--spacing-24) !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
  padding-bottom: var(--spacing-24) !important;
}

.py-24 {
  padding-top: 1.5rem !important;
  padding-top: var(--spacing-24) !important;
  padding-bottom: 1.5rem !important;
  padding-bottom: var(--spacing-24) !important;
}

.pt-32 {
  padding-top: 2rem !important;
  padding-top: var(--spacing-32) !important;
}

.pb-32 {
  padding-bottom: 2rem !important;
  padding-bottom: var(--spacing-32) !important;
}

.py-32 {
  padding-top: 2rem !important;
  padding-top: var(--spacing-32) !important;
  padding-bottom: 2rem !important;
  padding-bottom: var(--spacing-32) !important;
}

.pt-48 {
  padding-top: 3rem !important;
  padding-top: var(--spacing-48) !important;
}

.pb-48 {
  padding-bottom: 3rem !important;
  padding-bottom: var(--spacing-48) !important;
}

.py-48 {
  padding-top: 3rem !important;
  padding-top: var(--spacing-48) !important;
  padding-bottom: 3rem !important;
  padding-bottom: var(--spacing-48) !important;
}

.pt-64 {
  padding-top: 4rem !important;
  padding-top: var(--spacing-64) !important;
}

.pb-64 {
  padding-bottom: 4rem !important;
  padding-bottom: var(--spacing-64) !important;
}

.py-64 {
  padding-top: 4rem !important;
  padding-top: var(--spacing-64) !important;
  padding-bottom: 4rem !important;
  padding-bottom: var(--spacing-64) !important;
}

.pt-80 {
  padding-top: 5rem !important;
  padding-top: var(--spacing-80) !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
  padding-bottom: var(--spacing-80) !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-top: var(--spacing-80) !important;
  padding-bottom: 5rem !important;
  padding-bottom: var(--spacing-80) !important;
}

.pt-128 {
  padding-top: 8rem !important;
  padding-top: var(--spacing-128) !important;
}

.pb-128 {
  padding-bottom: 8rem !important;
  padding-bottom: var(--spacing-128) !important;
}

.py-128 {
  padding-top: 8rem !important;
  padding-top: var(--spacing-128) !important;
  padding-bottom: 8rem !important;
  padding-bottom: var(--spacing-128) !important;
}

.bg-violet-100 {
  background-color: #7c3bff !important;
  background-color: var(--color-violet-100) !important;
}

.bg-violet-200 {
  background-color: var(--color-violet-200) !important;
}

.bg-violet-300 {
  background-color: var(--color-violet-300) !important;
}

.bg-violet-400 {
  background-color: #6930db !important;
  background-color: var(--color-violet-400) !important;
}

.btn-violet {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #6930db !important;
  background-color: var(--color-violet-400) !important;
}

.btn-violet-outline {
  color: #6930db !important;
  color: var(--color-violet-400) !important;
  border-color: #6930db !important;
  border-color: var(--color-violet-400) !important;
}

.btn-violet-outline:hover {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #6930db !important;
  background-color: var(--color-violet-400) !important;
  border-color: #6930db !important;
  border-color: var(--color-violet-400) !important;
}

.btn-violet-outline-r {
  color: #6930db !important;
  color: var(--color-violet-400) !important;
  border-color: #6930db !important;
  border-color: var(--color-violet-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-violet-rounded {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #6930db !important;
  background-color: var(--color-violet-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-orange {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #ffa201 !important;
  background-color: var(--color-orange-400) !important;
}

.btn-orange-outline {
  color: #6930db !important;
  color: var(--color-violet-400) !important;
  border-color: #ffa201 !important;
  border-color: var(--color-orange-400) !important;
}

.btn-orange-outline:hover {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #ffa201 !important;
  background-color: var(--color-orange-400) !important;
  border-color: #ffa201 !important;
  border-color: var(--color-orange-400) !important;
}

.btn-orange-outline-r {
  color: #ffa201 !important;
  color: var(--color-orange-400) !important;
  border-color: #ffa201 !important;
  border-color: var(--color-orange-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-orange-rounded {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #ffa201 !important;
  background-color: var(--color-orange-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-special-outline {
  color: #ff9110 !important;
  color: var(--color-special) !important;
  border-color: #ff9110 !important;
  border-color: var(--color-special) !important;
}

.btn-special-outline-r {
  color: #ff9110 !important;
  color: var(--color-special) !important;
  border-color: #ff9110 !important;
  border-color: var(--color-special) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-special-rounded {
  color: #ff9110 !important;
  color: var(--color-special) !important;
  background-color: #ff9110 !important;
  background-color: var(--color-special) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-danger {
  color: #fff !important;
  color: var(--color-white) !important;
  border-color: #cd3636 !important;
  border-color: var(--color-red-400) !important;
}

.btn-danger-outline {
  color: #cd3636 !important;
  color: var(--color-red-400) !important;
  border-color: #cd3636 !important;
  border-color: var(--color-red-400) !important;
}

.btn-danger-outline-r {
  color: #cd3636 !important;
  color: var(--color-red-400) !important;
  border-color: #cd3636 !important;
  border-color: var(--color-red-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.btn-danger-rounded {
  color: #cd3636 !important;
  color: var(--color-red-400) !important;
  background-color: #cd3636 !important;
  background-color: var(--color-red-400) !important;
  border-radius: 2rem !important;
  border-radius: var(--spacing-32) !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-violet {
  color: #6930db;
  color: var(--color-violet-400);
}

.text-orange-100 {
  color: #fffff0;
  color: var(--color-orange-100);
}

.text-orange-400 {
  color: #ffa201;
  color: var(--color-orange-400);
}

.text-orange-600 {
  color: #ff7a1a;
  color: var(--color-orange-600);
}

.pointer {
  cursor: pointer !important;
}

a {
  text-decoration: none !important;
}

input[type="text"],
textarea {
  outline: none !important;
  box-shadow: none !important;
}

/* Dashboard header - >576px */
.header {
  min-height: 4rem;
  min-height: var(--spacing-64);
}

.header a {
  color: var(--black);
  font-size: 15px;
}

/* Dashboard tabs - >576px */
.tabs {
  background: #fff;
  background: var(--color-white);
  display: flex;
  overflow-x: auto;
  box-shadow: inset 0 -0.125rem 0 #f1f2f5;
  box-shadow: inset 0 -0.125rem 0 var(--color-line-light);
  height: calc(3rem + 0.0625rem);
  height: calc(var(--spacing-48) + 0.0625rem);
}

.tab-item {
  align-items: center;
  box-shadow: inset 0 -0.125rem 0 transparent;
  color: #696e7c;
  color: var(--color-text-light);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  justify-content: center;
  padding: 0 0.5rem;
  padding: 0 var(--spacing-8);
  transition-property: box-shadow, color;
}

.tab-item:hover {
  color: #6930db;
  color: var(--color-violet-400);
}

.tab-item.-active {
  color: #2c3242;
  color: var(--color-text);
  border-bottom: 3px solid #2b2b2b;
  border-bottom: 3px solid var(--color-black-400);
  border-radius: 2px;
  /* box-shadow: inset 0 -0.125rem 0 var(--color-black-400); */
}

/* Dashboard content - >576px */
.dashboard-content {
  background-color: #fff;
  background-color: var(--color-white);
  min-height: 100vh;
}

/* Hide emulator warning locally */
.firebase-emulator-warning {
  display: none;
}

.spinner-border {
  color: #6930db;
  color: var(--color-violet-400);
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(/static/media/Loader.e7b4e022.svg) 50% 50% no-repeat;
  background-size: 50px 50px;
  -webkit-animation: rotate360 2s infinite;
          animation: rotate360 2s infinite;
}

/* Toastify overriding colors */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success,
.Toastify__toast-theme--colored.Toastify__toast--success {
  /* background-color: var(--color-violet-400) !important; */
}

.Toastify__close-button {
  color: #fff !important;
  color: var(--color-white) !important;
}

.Toastify--animate-icon {
  display: none !important;
}

.Toastify__toast--success {
  background: #6930db !important;
  background: var(--color-violet-400) !important;
}

.Toastify__toast--warning {
  background: #f9cb40 !important;
  background: var(--color-yellow-400) !important;
}

.Toastify__toast--error {
  background: #cd3636 !important;
  background: var(--color-red-400) !important;
}

.toast-progress {
  background-color: #fff !important;
  background-color: var(--color-white) !important;
}

.toast-body {
  text-align: center;
  color: #fff !important;
  color: var(--color-white) !important;
}

@-webkit-keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .navbar {
    box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 1px 0px rgb(0 0 0 / 24%) !important;
  }

  .header .container-fluid {
    padding: 0px !important;
  }

  .hide-sm {
    display: none;
  }

  .tab-item {
    font-size: 0.875rem;
    font-size: var(--font-size);
  }

  .dashboard-content .dashboard-tabs.-xs-d-none {
    display: none !important;
  }

  .dashboard-content.xs-bg-none {
    background-color: #fff !important;
    background-color: var(--color-white) !important;
  }
}

@media (min-width: 576px) {
  .dashboard-content {
    /* margin-left: 16.25rem; */
    margin: 0px auto;
    max-width: 70vw;
  }

  .dashboard-content .desktop-align-center {
    margin-top: 5rem !important;
    margin-top: var(--spacing-80) !important;
    padding: 1rem;
    padding: var(--spacing-16);
    background-color: #fff;
    background-color: var(--color-white);
    width: 40vw !important;
  }

  .tabs.-big {
    height: calc(4rem + 0.0625rem);
    height: calc(var(--spacing-64) + 0.0625rem);
  }

  .fixed-top-lg {
    top: 63px !important;
    max-width: 50vw !important;
    margin: 0px auto !important;
  }
}

@media (min-width: 768px) {
  .dashboard-content {
    /* margin-left: 16.25rem; */
    margin: 0px auto;
    max-width: 50vw;
  }
}

.go-back-icon {
  margin-right: 10px;
}

.settings-icon {
  width: 27px;
}

.dot-icon {
  width: 10px;
}

.save-icon {
  width: 16px;
}

.challenges-wrapper {
  text-align: center;
  padding: 5rem;
}

.challenges-wrapper .launch-icon {
  width: 42px;
  -webkit-animation: wiggle 4s infinite;
          animation: wiggle 4s infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.challenges-wrapper .launch-text {
  font-size: 13px;
  font-weight: 800;
}

.purchase-wrapper {
  text-align: center;
  padding: 5rem;
}

.purchase-wrapper .launch-icon {
  width: 62px;
  -webkit-animation: wiggle 4s infinite;
          animation: wiggle 4s infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.purchase-wrapper .launch-text {
  font-size: 13px;
  font-weight: 800;
}

.redeem-wrapper {
  text-align: center;
  padding: 5rem;
}

.redeem-wrapper .launch-icon {
  width: 62px;
  -webkit-animation: wiggle 4s infinite;
          animation: wiggle 4s infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes wiggle {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.redeem-wrapper .launch-text {
  font-size: 13px;
  font-weight: 800;
}

/* Subheader */
.feeling-sub-header {
  margin-top: -12px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.feeling-sub-header div {
  padding: 0.4rem;
}

.feeling-sub-header a {
  color: #1a1a2e;
  font-size: 12px;
  font-weight: 900;
  padding: 0.5rem 1rem;
}

.feeling-sub-header div:nth-child(1) {
  border-bottom: 2px solid;
}

/* Create post wrapper - steps */
.create-post-wrapper {
  padding: 1.5rem 0;
}

.feeling-detail-wrapper,
.feeling-category-wrapper,
.feeling-submit-wrapper {
  font-size: var(--font-size);
}

.feeling-detail-wrapper {
  position: relative;
  margin-bottom: var(--spacing-16);
}

.feeling-detail-wrapper .description textarea {
  font-size: var(--font-size);
  border: none;
}

.feeling-detail-wrapper .trash-icon {
  width: 19px;
}

.feeling-submit-wrapper {
  display: flex;
}

.feeling-submit-wrapper .display-right {
  margin-left: auto;
}

.feeling-submit-wrapper .checkbox-icon {
  width: 19px;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.attachment-icon {
  width: var(--font-size-bigger);
}

/* custom select */
.custom-select {
  border: none !important;
  box-shadow: none !important;
}

.activity-sub-header {
  margin-top: -12px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.activity-sub-header div {
  padding: 0.4rem;
}

.activity-sub-header a {
  color: #1a1a2e;
  font-size: 12px;
  font-weight: 900;
  padding: 0.5rem 1rem;
}

.activity-sub-header div:nth-child(2) {
  border-bottom: 2px solid;
}

.activity-name-wrapper {
  font-size: var(--font-small) !important;
  background: var(--color-white);
  margin: var(--spacing-32) var(--spacing-16);
  padding: var(--spacing-4) var(--spacing-16);
}

.activity-name-wrapper .header {
  padding: 1rem 0 0;
}

.activity-name-wrapper .options {
  line-height: 2;
}

/* Description */
.activity-detail-wrapper {
  font-size: var(--font-small) !important;
}

.activity-submit-wrapper {
  display: flex;
  margin-top: var(--spacing-8);
}

.activity-submit-wrapper .display-right {
  margin-left: auto;
}

.checkbox-icon {
  width: 21px;
}

.activity-sub-header {
  position: fixed;
  margin-top: -17px;
  width: 100%;
  background: white;
  z-index: 9;
}

.activity-card-wrapper {
  padding: 1rem 0.75rem;
  margin: 1rem;
}

.activity-card-wrapper .btn {
  font-size: var(--font-size-bigger);
  color: var(--color-gray-600);
}

.activity-card-wrapper .fa {
  font-size: var(--font-size-bigger);
  color: var(--color-gray-600);
}

.activity-card-wrapper .fa.-active {
  color: var(--color-violet-400);
}

.activity-card-wrapper .created-at {
  font-size: var(--font-size);
}

.activity-card-wrapper .card-badge {
  font-size: small;
  background-color: var(--color-gray-100);
  padding: 0.4rem 0.7rem;
  border-radius: 8px;
  float: left;
}

.activity-card-wrapper .card-follow {
  float: right;
}

.activity-card-wrapper .card-body {
  padding-bottom: 0;
  padding-left: 0.3rem;
  padding-top: 0.5rem;
  padding-right: 0.3rem;
}

.activity-card-wrapper .card-body .description {
  font-size: var(--font-size);
  padding-top: var(--spacing-8);
}

@media (min-width: 576px) {
  .activity-wrapper {
    padding: 0rem 3rem 5rem;
  }

  .activity-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

/* Cards page */
.payment-cards-wrapper .trash-icon {
  float: right;
  width: 20px;
}

.payment-cards-wrapper .card-number {
  font-size: 14px;
}

.payment-cards-wrapper .card-name {
  font-size: 12px;
}

/* Add card page */
.add-card-wrapper label {
  font-size: 12px;
}

.add-card-wrapper input[type="text"],
.add-card-wrapper input[type="number"],
.add-card-wrapper input[type="password"] {
  box-shadow: none;
  font-size: 13px;
}

.add-price-wrapper .form-label {
  font-size: 11px;
}

.unlock-profile-wrapper .btn-next {
  font-size: 12px;
  border: 1px solid #1a1a2e;
}

/* calculate price */
.calculate-price-wrapper .per-month .label,
.calculate-price-wrapper .total-months .label,
.calculate-price-wrapper .total-price .label {
  font-size: 14px;
}

.calculate-price-wrapper .total-months .value {
  box-shadow: none !important;
}

.calculate-price-wrapper .total-price .value {
  font-weight: 900;
}

/* Payment method */
.payment-method-wrapper .header {
  font-size: 13px;
  padding: 0.5rem 0;
}

/* Invoice/Submit page */
.invoice-wrapper {
  background-color: rgba(0, 0, 0, 0.025);
}

.invoice-wrapper .label {
  font-size: 13px;
}

.invoice-wrapper .value {
  font-size: 14px;
}

.purchase-orders-wrapper .order-name {
  text-transform: capitalize;
}

.purchase-orders-wrapper .order-price {
  font-size: 12px;
}

.purchase-orders-wrapper .order-status {
  color: rgba(0, 128, 0, 0.8);
  font-size: 13px;
}

.purchase-orders-wrapper .order-validity {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

/* Dashboard sidebar - >576px */
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 16.25rem;
  flex-shrink: 0;
  min-height: 100vh;
}

.sidebar .header .nav-link {
  padding: var(--spacing-8);
}

.sidebar .header .nav-link:first-child {
  background-color: var(--color-gray-100);
}

.sidebar .header .nav-link .static-avatar {
  height: var(--spacing-48);
  width: var(--spacing-48);
  border-radius: 50%;
  float: left;
}

.sidebar .header .nav-link .static-avatar.-no {
  background-color: var(--color-violet-400);
  color: var(--color-white);
  padding-top: var(--spacing-8);
  font-size: var(--font-size-bigger);
  text-align: center;
  text-transform: capitalize;
}

.sidebar .header .nav-link .static-text .title {
  color: var(--color-text);
  text-transform: capitalize;
  padding-top: var(--spacing-8);
  font-size: var(--font-size-bigger);
}

.sidebar .header .nav-link:first-child .static-text .caption {
  font-size: var(--font-size);
}

.sidebar .header .nav-link .static-text img {
  width: var(--font-size-bigger);
  padding-top: var(--spacing-8);
  float: right;
}

.sidebar .header .nav-link.-sm img {
  width: var(--spacing-24);
  float: left;
}

.sidebar .header .nav-link.-sm .static-text {
  font-size: var(--font-size);
  padding: 0.75rem 2rem 0;
}

/* Sidebar footer */
.sidebar .footer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  background-color: var(--color-gray-100);
}

.sidebar .footer .nav-link {
  color: var(--color-text);
  text-transform: capitalize;
  padding: var(--spacing-16);
  width: 16rem;
  text-align: center;
}

/* Login page */
.login-page {
  /* background-image: url("assets/images/login.png"); */
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
}

.login-form .row {
  padding: var(--spacing-160) var(--spacing-16) 0;
}

/* Login page : Left block */
.login-form .left-block {
  background: rgba(105, 48, 219, 0.7);
  box-sizing: border-box;
  color: var(--color-white);
}

.login-form .left-block .header {
  padding: var(--spacing-48) var(--spacing-12) var(--spacing-16);
}

.login-form .left-block .body {
  padding: 0 var(--spacing-12) var(--spacing-24);
}

.login-form .left-block .body img:first-child {
  width: 11vw;
}

.login-form .left-block .body .learn-more {
  cursor: pointer;
}

/* Login page : Right block */
.login-form .right-block {
  background: var(--color-white);
  box-shadow: -6px 4px 39px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.login-form img.show-password {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
}

.login-form .right-block .header {
  padding: var(--spacing-48) var(--spacing-12) var(--spacing-16);
}

.login-form .right-block .body {
  padding: 0 var(--spacing-32) var(--spacing-64);
}

.login-form .right-block .body input {
  min-height: 50px;
  border-radius: var(--spacing-24);
  border: 2px solid var(--color-violet-100);
  text-indent: var(--spacing-12);
}

.login-form .right-block .body .input-group-text {
  background-color: var(--color-violet-100);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0px;
}

.login-form .right-block .body .input-group-text div {
  width: 38px;
  height: 38px;
  padding: var(--spacing-8);
  border-radius: 50%;
  background-color: var(--color-white);
}

.login-form .right-block .body .input-group-text img {
  position: relative;
  top: -4px;
  height: 20px;
}

.login-form .right-block .body .input-group-text img.mail-icon {
  position: relative;
  top: -2px;
  height: 20px;
}

.login-form .right-block .body .input-group-text.append {
  background-color: var(--color-white);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: var(--spacing-24);
  border-bottom-right-radius: var(--spacing-24);
  border: 2px solid var(--color-violet-100);
  border-left: 0px;
}

.login-form .right-block .body .forgot-pw {
  color: #8a8a8a;
}

.login-form .right-block .body .login-btn {
  color: var(--color-white);
  background: linear-gradient(
    180deg,
    var(--color-violet-400) 0%,
    var(--color-violet-100) 100%
  );
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border: 0px;
  border-radius: 15px;
  margin: var(--spacing-16) 0;
  height: 42.68px;
}

.login-form .right-block .body .google-login-btn {
  background-color: var(--color-white);
  color: var(--color-violet-400);
  /* border-color: linear-gradient(180deg, #7c3bff 0%, #2e087a 100%); */
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 0px;
  border-radius: 15px;
  margin: var(--spacing-16) 0;
  height: 42.68px;
}

.login-form .right-block .body .signup-btn {
  color: var(--color-violet-400);
}

.login-form input,
.login-form input[type="email"] {
  box-shadow: none !important;
  outline: none !important;
}

/* Login page : Footer */
.login-form .footer {
  color: var(--color-white);
  text-align: center;
  margin: 0px auto;
  padding: var(--spacing-128) 0 var(--spacing-16);
  font-size: var(--font-size-bigger);
}

.login-form .footer .company-name {
  padding-top: var(--spacing-32);
  font-size: var(--font-size-small);
}

.login-form .right-block .body input[type="checkbox"] {
  min-height: 0px;
}

/* Forgot pw page */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .login-form .row {
    padding: var(--spacing-128) var(--spacing-4) 0;
  }

  .login-form .left-block {
  }

  .login-form .right-block {
    padding: 0px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .login-page .container {
    max-width: 70%;
  }

  .login-form .row {
    min-height: 80vh;
    padding: var(--spacing-160) var(--spacing-16) 0;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 50vw;
    margin: 0px auto;
  }

  /* signup */
  .signup-page .container.login-form {
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-page .container.login-form,
  .signup-page .container.login-form {
    max-width: 90%;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 45vw;
    margin: 0px auto;
  }
}

@media (min-width: 1200px) {
  .login-page .container.login-form {
    max-width: 60%;
  }

  .login-form .footer {
    max-width: 25vw;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 40vw;
    margin: 0px auto;
  }

  /* signup */
  .signup-page .container.login-form {
    max-width: 70%;
  }
}

.dashboard-content.-open-route {
  margin: 0px auto;
  margin-top: var(--spacing-64);
}

.dashboard-content.-open-route .extra-section {
  text-align: center;
}

.dashboard-content.-open-route .extra-section img {
  width: 4vw;
  margin: var(--spacing-32) 0;
}

@media (min-width: 576px) {
  .dashboard-content.-open-route {
    max-width: 85vw;
  }
}

.modal-dialog-sm {
  max-width: 40% !important;
}

.alerts-wrapper .card {
  border-width: 0px;
  border-bottom: 1px solid var(--color-gray) !important;
  /* box-shadow: 1px 1px 2px gainsboro; */
}

.active-alert {
  background-color: var(--color-violet-100) !important;
}

* {
  /* font-family: var(--font-family-display); */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.App {
  font-family: sans-serif;
  /*text-align: center;*/
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.fa {
  cursor: pointer;
}

.font-small {
  font-size: small !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.letter-spacing-2 {
  letter-spacing: 0.05em;
}

.white-space-preline {
  white-space: pre-line;
}

.card-br-0 {
  border-radius: 0px !important;
}

.br-0 {
  border-radius: 0px !important;
}

.text-grey {
  color: #7f8fa6;
}

.text-native {
  color: rgba(220, 53, 69, 1) !important;
}

.text-default {
  color: #000000 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.decoration-none {
  text-decoration: none !important;
}

.page-top-alert {
  position: absolute;
  /*bottom: 50px;*/
  top: 0px;
  left: 42vw;
  margin: 0px auto;
  z-index: 99999;
  background-color: #dc3545;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
}

.fw-900 {
  font-weight: 900 !important;
}

/* Sort options */
.sort-options {
  position: relative;
}

.sort-btn {
  color: #fff;
  position: fixed;
  bottom: 70px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  right: 3vw;
  background-color: #dc3545;
  z-index: 999;
}

.sort-btn i {
  margin-top: 12px;
}

.sort-menu {
  background-color: #fff;
  position: fixed;
  bottom: 120px;
  right: 3vw;
  padding: 10px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 13px;
  z-index: 1200;
  box-shadow: 0 0 1px 1px rgba(50, 50, 50, 0.1);
}

/* Audio controls */
audio {
  -webkit-filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
          filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  height: 45px;
  outline: none !important;
}

audio::-internal-media-controls-download-button {
  display: none;
}

/* Radio group */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  /* color: #666; */
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  border: 1px solid #a1a1a1;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  /* background: rgba(220, 53, 69, 0.8); */
  background: var(--color-violet-400);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

input[type="checkbox"]:checked {
  accent-color: var(--color-violet-400);
}

.text-between-line:before,
.text-between-line:after {
  content: "\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
}

.badge {
  vertical-align: middle !important;
  font-size: x-small !important;
  /* border-radius: 50% !important; */
}

.blur-image {
  -webkit-filter: blur(4px) !important;
          filter: blur(4px) !important;
}

input.form-input {
  /*max-width: 450px;*/
  width: auto;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /*transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);*/
  padding: 10px;
}

input[type="date"] {
  min-width: 95%;
}

.btn-sm-app {
  /*outline: none;*/
  color: #fff !important;
  min-width: 100px !important;
  font-size: 16px !important;
  padding: 5px 7px !important;
  background-color: #dc3545 !important;
}

button.login {
  width: 125px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  padding: 7px;
  text-align: center;
  cursor: pointer;
}

button.login[type="submit"] {
  background-color: #4c566a;
  color: white;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 15px;
}

button:focus,
button:active {
  box-shadow: none !important;
  outline: none !important;
  background-color: initial;
}

.googleBtn {
  width: 225px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.googleBtn img {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
}

/* Signup page */
.signup-form {
  font-size: 13px;
}

.signup-form input {
  border-radius: 0px;
  font-size: 13px;
  box-shadow: none !important;
}

.signup-form .input-password {
  position: relative;
}

.signup-form .input-password .show-password {
  position: absolute;
  top: 36px;
  right: 10px;
  font-size: 17px;
}

.signup-form .btn-submit {
  border: 1px solid;
  border-radius: 0px;
  min-height: 7vh;
  min-width: 35vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.signup-form a {
  font-size: 14px;
}

.nav {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  justify-content: flex-start;
  padding: 0;
}

.nav li {
  margin: 0 10px;
}

.beta-badge {
  border-radius: 3px !important;
  letter-spacing: 0.5px !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

.count-badge {
  position: relative;
  top: -5px;
}

/* Profile page */
.profile-pic-section {
  position: relative;
  cursor: pointer;
}

.profilePic {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s;
}

.profile-pic-section .hover-text {
  display: none;
  position: absolute;
  top: 53px;
  left: 18px;
  color: #000;
  transition: all 0.3s;
}

.profile-pic-section:hover .profilePic {
  opacity: 0.1;
  border: 1px solid rgba(0, 0, 0, 0.7);
}

.profile-pic-section:hover .hover-text {
  display: inline;
}

.profile-options {
  overflow-x: auto;
  text-align: center;
  overflow-y: hidden;
}

.profile-options::-webkit-scrollbar {
  display: none;
}

.profile-options div.option {
  font-size: 13px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

.profile-options div.option div:after {
  content: "\b7";
  font-size: 34px;
  color: #dc3545;
}

.profile-options div.option .active {
  margin-top: -21px !important;
  height: 33px;
}

.profile-options div.option:first-child {
  /*padding-left: 0.5rem;*/
}

/* Profile: Permissions */
.permissions-wrapper {
  /*text-align: center;*/
  font-size: 13px;
  /* margin-left: 12px; */
}

/* Profile: Settings page */
.change-password-form {
  padding: 10px;
  font-size: 13px;
  position: relative;
}

.change-password-form .show-password {
  position: absolute;
  font-size: 18px;
  right: 22px;
  top: 48px;
}

/* Notification */
.notification {
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  max-width: 300px;
  z-index: 9999;
}

.fadeOut {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icon-bg-dark {
  cursor: pointer;
  font-size: 21px;
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: 48px;
}

.cookie-banner {
  font-size: var(--font-size);
  position: fixed;
  bottom: 0px;
  padding: 0.4em 0.9em 1.4em;
  border: 1px solid #eee;
  align-items: center;
  background-color: #fff;
  z-index: 999;
}

/* Default Layout */
.navbar {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding-right: 0.6rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.hidden-spacer {
  height: 56px;
}

.nav-link a {
  /*color: #DC3545 !important;*/
}

.navbar-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navbar-nav {
  text-align: center;
}

.navbar-nav .nav-item a {
  /* padding: 0 0.6rem; */
  transition: all 0.35s;
}

.navbar-nav .nav-support,
.navbar-nav .nav-support a {
  color: #fff !important;
  background-color: rgba(220, 53, 69, 1);
  /*background-color: #6C757D;*/
  padding-top: 4px;
}

.navbar-nav .nav-item:hover {
  /*background-color: rgba(220, 53, 69, 1);*/
  cursor: pointer;
}

.navbar-nav .nav-item:hover a,
.navbar-nav .nav-item:hover a:hover {
  /*color: #fff !important;*/
  text-decoration: none;
}

.navbar-nav .nav-item-active {
  border-bottom: 2px solid #6c757d;
  color: red;
}

.navbar-nav .nav-profile:hover {
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

/* Home */
.post-textbox,
.post-textbox:focus,
.post-textbox:active {
  width: 100%;
  border: 3px solid #fff;
  border-radius: 0.4em;
  outline: none;
  resize: none;
  padding: 10px;
}

.create-post > .input-group > .input-group-prepend > .input-group-text {
  background-color: #fff !important;
  border-radius: 0px;
}

.create-post-card {
  border-radius: 0px !important;
}

.create-post-card-type {
  cursor: pointer;
  border: 0px solid #fff !important;
  border-radius: 0px !important;
  color: #6c757d !important;
}

.continue-posts .left-btn,
.continue-posts .right-btn {
  font-size: larger;
}

.post-menu-dropdown {
  position: static !important;
}

.post-menu-icon {
  font-size: x-large;
}

.post-menu-dropdown .dropdown-menu {
  padding: 0px !important;
  font-size: small !important;
  min-width: 5rem !important;
  left: -10px !important;
}

/* Home page - Desktop */
.left-sidebar-wrapper {
  position: fixed !important;
  min-width: 11rem !important;
}

.left-sidebar-wrapper .card-body {
  padding: 20px 0px !important;
}

.left-sidebar-wrapper a,
.left-sidebar-wrapper a:hover,
.left-sidebar-wrapper a:focus,
.left-sidebar-wrapper a:active,
.alerts-wrapper a,
.alerts-wrapper a:hover,
.alerts-wrapper a:focus,
.alerts-wrapper a:active {
  color: #000000 !important;
  text-decoration: none !important;
}

.left-sidebar-wrapper .profile-details {
  text-align: center;
  cursor: pointer;
}

.left-sidebar-wrapper .profile-pic {
  height: 57px;
  width: 57px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.left-sidebar-wrapper .profile-name {
  font-size: 13px;
  margin-top: 10px;
  text-decoration: none !important;
}

.left-sidebar-wrapper .create-post {
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.right-sidebar-wrapper {
  position: fixed !important;
  width: 16rem !important;
}

.right-sidebar-wrapper .card-body {
  font-size: 13px;
  padding: 20px 0px 0px !important;
  text-align: justify;
}

.right-sidebar-wrapper a,
.right-sidebar-wrapper a:hover,
.right-sidebar-wrapper a:focus,
.right-sidebar-wrapper a:active {
  color: #000000 !important;
  text-decoration: none !important;
}

.right-sidebar-wrapper .profile-bot {
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.right-sidebar-wrapper .profile-bot-description {
  padding-left: 10px;
  padding-right: 10px;
}

.right-sidebar-wrapper .profile-bot .bot-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.right-sidebar-wrapper .profile-bot .bot-text {
  padding-left: 10px;
  font-size: 14px;
}

/* Share post page */
.instaButton {
  background-color: rgba(245, 96, 64, 0.7) !important;
  border: none;
  padding: 0px;
  font-size: 23px !important;
  color: white !important;
  cursor: pointer;
  min-width: 34px;
}

/* Surveys */
.survey-textbox,
.survey-textbox:focus,
.survey-textbox:active {
  width: 100%;
  background-color: #eee;
  border: 3px solid #fff;
  border-radius: 0.4em;
  outline: none;
  resize: none;
  padding: 10px;
}

.survey-question {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.survey-input {
  margin: 0;
  border: 0px !important;
  border-bottom: 1px solid #aaa !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
  font-size: 14px !important;
}

.survey-input:focus,
.survey-input:active,
.select:focus,
.select:active {
  box-shadow: none !important;
  border-bottom: 2px solid #dc3545 !important;
}

/* Modal component */
.modal .modal-dialog {
  max-width: 100% !important;
}

.modal-subtitle {
  font-size: 14px;
}

/* Form Fields Component */
.form-check-label {
  /* font-size: 14px !important; */
}

.select {
  border: 0px !important;
  border-bottom: 1px solid #aaa !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* Search component */
.card-body {
  /* padding: 10px 10px 0px 10px !important; */
}

.card-title {
  font-size: 15px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

/* .profile-card-img {
  position: relative;
  color: #fff;
}

.profile_card_img img {
  object-fit: contain;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  transition: all 0.3s;
}

.profile-card-img .img-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile_card_img h6 a {
  color: #000;
  text-decoration: none;
}

.profile_card_big_img {
  width: 100%;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: none;
  background-color: rgba(0,0,0,0.9);
  transition: all 0.3s;
}

.profile_card_big_img img {
  width: 100% !important;
  height: 125px !important;
  object-fit: contain !important;
  transition: all 0.3s !important;
  transform: scale(1, 1) !important;
}

.profile_card_big_img img:active,
.profile_card_big_img img:focus {
  width: 100% !important;
  height: 125px !important;
  object-fit: contain !important;
} */

.btn_follow small {
  padding: 1px 7px !important;
}

.btn_send_text {
  font-size: medium;
  padding: 2.5px 7px !important;
}

.nav-pills .nav-link {
  color: #6c757d !important;
  font-size: small;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #6c757d !important;
  border-bottom: 2px solid !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

/* Payments Component */
.subscription-plan-card {
  border: 2px solid #dc3545 !important;
  border-radius: 5px !important;
}

.subscription-plan-card .card-header {
  border-radius: 0px !important;
}

.subscription-plan-card body {
  padding: 10px !important;
}

.subscription-plan-card body .small {
  font-size: 10px !important;
}

.payment-card-list {
  list-style-type: none;
  font-size: 13px;
  padding-left: 0px;
}

.payment-card-list li:before {
  content: "\2713";
  padding-right: 10px;
}

.payment-card-list li {
  border-bottom: 2px dotted #aaa;
  padding: 5px 10px 5px 15px;
}

.payment-card-list li:last-child {
  border-bottom: none;
  padding: 5px 10px 0px 15px;
}

/* Chatbot */
.chatbot {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dc3545;
  color: #fff;
}

.chatbot i {
  font-size: 22px;
  position: relative;
  left: 27%;
}

.sidebar {
  overflow-y: scroll;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.login-page-clipart {
  font-family: "Poppins" !important;
  text-align: center;
}

.login-page-clipart img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.login-page-clipart .login-page-caption {
  position: relative;
  top: -10px;
  font-size: xx-large;
  font-weight: 800;
}

/* Mobile devices */
.mob-navbar-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: initial;
}

/* Media queries */
@media (max-height: 569px) {
}

@media (max-width: 575px) {
  audio {
    width: 85vw !important;
  }

  .font-xs-small {
    font-size: small !important;
  }

  .login-page-clipart .login-page-caption {
    font-size: large;
  }

  .bot-chat-window-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: white;
  }
}

@media (min-width: 576px) {
  audio {
    width: 80vw !important;
  }

  .fs-sm-12 {
    font-size: 12px !important;
  }

  .fs-sm-14 {
    font-size: 14px !important;
  }

  .fs-sm-18 {
    font-size: 18px !important;
  }

  .fs-sm-22 {
    font-size: 22px !important;
  }
}

@media (min-width: 768px) {
  .App {
    padding: 50px;
  }

  .modal .modal-dialog {
    max-width: 80% !important;
  }

  audio {
    width: 25vw !important;
  }

  .btn-submit {
    min-width: 12vw !important;
    min-height: 6vh !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-black);
}

.separator::before,
.separator::after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--color-black);
}

.separator:not(:empty)::before {
  margin-right: var(--spacing-16);
}

.separator:not(:empty)::after {
  margin-left: var(--spacing-16);
}

.login-page {
  font-weight: var(--font-weight-medium);
  padding-bottom: var(--spacing-160);
}

.login-page .caption-text {
  color: var(--color-gray-700);
  font-size: var(--font-size-small);
}

.login-page .img-nmc-logo {
  width: 350px;
  object-fit: contain;
}

.login-page .img-medoc-logo {
  width: 100px;
}

@media (min-width: 576px) {
  .login-page {
    margin-top: var(--spacing-128);
  }
}

.signup-page .login-form .left-block {
  padding: var(--spacing-48) var(--spacing-64);
}

.signup-page .login-form .left-block .header {
  padding-left: 0;
  font-family: "Quicksand", sans-serif !important;
}

.signup-page .login-form .left-block .header h1 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.035rem;
  font-family: "Quicksand", sans-serif !important;
}

.signup-page .login-form .left-block .header .subheader {
  font-size: 16px;
}

.signup-page .login-form .left-block .options {
  padding: var(--spacing-24) 0;
  height: 28vh;
}

.signup-page .options {
  text-align: left;
  padding: 0 var(--spacing-16);
  font-size: var(--font-size-small);
  /* color: #8a8a8a; */
}

.signup-page .center-wrapper {
  padding: var(--spacing-8) 0;
}

.signup-page .center-wrapper .option {
  padding: var(--spacing-12) 0;
  align-items: center;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
}

.signup-page .center-wrapper .option .icon {
  border: 1px solid var(--color-white);
  border-radius: 13px;
  padding: var(--spacing-8);
  text-align: center;
  margin-right: var(--spacing-12);
}

.signup-page .center-wrapper .option .icon img {
  width: 1.6rem;
}

.signup-page .center-wrapper .option .option-text {
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .signup-page .login-form .left-block {
    text-align: center;
    padding: var(--spacing-48) var(--spacing-32);
  }
  .signup-page .center-wrapper .option .icon {
    margin: 0px auto;
    padding: var(--spacing-16);
  }
  .signup-page .center-wrapper .option .icon img {
    width: 2.6rem;
  }
  .signup-page .center-wrapper .option {
    padding-top: var(--spacing-16);
    font-size: 1.3rem;
    letter-spacing: 0.025rem;
  }
}

@media (min-width: 768px) {
  .signup-page .login-form .left-block .header {
    /* width: 20vw; */
  }

  .signup-page .login-form .left-block .subheader {
    /* width: 20vw; */
  }

  .signup-page .login-form .left-block .options {
    height: 33vh;
  }
}

/* header css */
a.bell-active {
  background-color: var(--color-white);
  border-radius: 50% 0 0 0;
  padding: 0.85rem !important;
  position: relative;
  top: 2px;
  left: 10px;
  /* -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 10px, transparent 20px, black 21px); */
}

a.bell-active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  left: -25px;
  height: 35px;
  width: 25px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 9px 0 0 var(--color-white);
  z-index: -99;
}

.alerts-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
}

.pending-request .badge.pending-count {
  width: 17px;
  height: 17px;
  padding-top: 4px;
  left: -15px;
}

.alerts-wrapper .mark-alerts {
  cursor: pointer;
  font-size: var(--font-size-small);
}

.alerts-wrapper .alert-dot {
  left: 4px;
}

.alerts-wrapper .card {
  border-width: 0px;
  border-bottom: 1px solid var(--color-gray) !important;
  /* box-shadow: 1px 1px 2px gainsboro; */
}

.alerts-wrapper .created-at {
  color: #2b2b2b;
  font-size: var(--font-size-smaller);
}

/* header css */
a.search-active {
  background-color: var(--color-white);
  border-radius: 50% 50% 0 0;
  padding: 0.85rem !important;
  position: relative;
  top: 3px;
  /* -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 10px, transparent 20px, black 21px); */
}

a.search-active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  left: -25px;
  height: 35px;
  width: 25px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 9px 0 0 var(--color-white);
  z-index: -99;
}

a.search-active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  right: -25px;
  height: 35px;
  width: 25px;
  border-bottom-left-radius: 20px;
  box-shadow: -5px 9px 0 0 var(--color-white);
  z-index: -99;
}

.search-input {
  min-height: 4vh;
  border-radius: 20px !important;
  background-color: var(--color-violet-100) !important;
  border: none !important;
  font-size: var(--font-size) !important;
}

.profile-user a.username {
  color: var(--color-black) !important;
  font-size: var(--font-size);
  float: left;
}

.profile-user a.username .actual-name {
  color: var(--color-gray-900);
  font-size: var(--font-size-small);
  position: relative;
  top: -5px;
}

.icon-search-chat {
  width: 20px !important;
  height: 20px !important;
}

/* Priority users */
.priority-user-list {
  border: 2px solid var(--color-gray-200);
  border-radius: var(--spacing-16);
  padding: var(--spacing-12) var(--spacing-16) 0;
}

.priority-user-list .priority-user-list-header {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.priority-user-list .priority-user {
  margin: var(--spacing-12) 0;
}

.priority-user-list .priority-user .search-user {
  padding: 0 !important;
}

@media (max-width: 576px) {
  .search-subheader {
    /* overflow-x: scroll !important; */
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  .search-subheader .tab-item {
    min-width: 50vw;
  }
}

.requests-subheader {
  padding: 36px var(--spacing-24) var(--spacing-12);
  vertical-align: middle;
  display: flex;
  flex-direction: row;
}

.user-container {
  padding: var(--spacing-12) var(--spacing-12);
}

.user-container .profile-user {
  align-self: center;
}

.user-container .profile-user .username {
  font-size: 16px;
}

.user-container .profile-user .actual-name {
  font-size: 14px !important;
}

.user-container .profile-user .request-result {
  font-size: 12px;
  color: #2b2b2b;
}

.icon-request {
  width: 20px;
  height: 20px;
}

.navbar-linear-violet {
  background: linear-gradient(180deg, #6930db -49.99%, #ffb47e 208.18%);
  border-radius: 0 0 1rem 1rem;
  min-height: var(--spacing-48);
  padding-left: var(--spacing-24);
}

.navbar-linear-violet .go-back-icon {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

.navbar-linear-violet .navbar-brand {
  color: var(--color-white);
  font-size: var(--font-size-big) !important;
  font-weight: 500 !important;
}

/* header */
.navbar-violet {
  background-color: var(--color-violet-400);
  min-height: var(--spacing-64);
}

.navbar-violet .navbar-nav {
  flex-direction: row !important;
}

.navbar-violet .navbar-brand {
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
}

.navbar-violet .nav-link img.icon-search,
.navbar-violet .nav-link img.icon-chat,
.navbar-violet .nav-link img.icon-alert {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.navbar-violet .nav-link img.icon-document-download {
  width: 23px;
  height: 23px;
}

.navbar-violet .nav-link img.icon-alert {
  width: 28px;
  height: 28px;
}

.nav-link sup {
  position: relative;
}

.dot-chat-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: -12px;
}

.dot-alert-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: -15px;
}

@media (min-width: 576px) {
  .navbar-violet .nav-link img.icon-document-download {
    width: 22px;
    height: 22px;
  }

  .navbar-violet .nav-link img.icon-alert {
    width: 27px;
    height: 22px;
  }
}

.static-header .navbar {
  min-height: var(--spacing-80);
  /* background-color: var(--color-orange-100); */
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-48);
  box-shadow: none !important;
  box-shadow: 0 1px 3px #f4effd, 0 1px 2px rgb(0 0 0 / 12%) !important;
}

.static-header .home-page-title {
  font-size: var(--spacing-24) !important;
  color: var(--color-violet-400) !important;
}

.static-header .navbar .fixed-after {
  height: 30px;
}

.static-header .home-page-title.after {
  font-size: 18px !important;
}

.static-header .navbar-toggler {
  border: none;
}

.static-header .add-on-text {
  color: var(--color-white);
  background-color: var(--color-violet-400);
  text-align: center;
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);
  font-weight: 500;
}

.footer {
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-80);
  background-color: var(--color-orange-100);
  color: var(--color-violet-800);
}

.footer h2.row-header {
  color: var(--color-violet-400);
}

.footer h5.row-header {
  letter-spacing: -0.5px;
}

.footer .row-list {
  padding: 0 !important;
  list-style-type: none;
  line-height: var(--spacing-32);
  font-weight: 600;
}

.cookie-banner {
  width: 100vw;
  text-align: center;
}

#createChatModal .chat-user .username {
  position: relative;
  top: -2px;
}

.btn-send {
  width: 17px;
  height: 17px;
}

#createChatModal .btn-send {
  position: relative;
  top: -10px;
}

@media (max-width: 767.98px) {
  #createChatModal .user-list {
    max-height: 50vh;
    overflow: scroll;
  }

  #createChatModal .modal-body {
    min-height: 35vh !important;
    max-height: 70vh !important;
  }

  #createChatModal .chat-user {
    padding: var(--spacing-4) 0;
  }

  #createChatModal .media-body {
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .accessibility-icon {
    display: none;
  }

  #createChatModal .modal-body {
    min-height: 35vh !important;
    max-height: 45vh !important;
    overflow-y: scroll;
    padding: 0 var(--spacing-32) 0;
  }

  #createChatModal .media-body {
    min-width: 15vw !important;
  }

  #createChatModal .chat-user {
    padding: var(--spacing-12) 0;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.measurements .input-group-append .input-group-text {
  background-color: var(--color-violet-400);
  border: 0px;
  color: var(--color-white);
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 2px dotted var(--color-violet-400);
  border-radius: 2px;
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

.tnc label {
  font-size: var(--font-size-small) !important;
}

.error-border {
  border: 1px solid var(--color-red-400) !important;
}

.error-color {
  color: var(--color-red-400) !important;
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

/* Category modal */
#selectPostCategoryModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
  cursor: pointer;
}

.text-color-special {
  color: var(--color-special);
}

#selectPostCategoryModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#selectPostCategoryModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

/* Detail page */
.w-100-p {
  width: 100%;
}

.microphone-icon {
  width: 20px;
  height: 20px;
}

.create-post-wrapper .card-badge {
  border: 0px;
}

.create-post-wrapper .create-post-box {
  width: 100%;
  border-width: 0px;
  resize: none;
  padding-top: var(--spacing-12);
}

.create-post-wrapper .trade-section-wrapper .slider-block {
  display: block;
  font-size: var(--font-size-small);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-post-wrapper .trade-section-wrapper.-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inr-black-icon {
  width: 11px;
  height: 11px;
}

/* Create-post-card */
.create-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.create-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.card-badge-special {
  background-color: var(--color-special) !important;
}

.create-post-card-wrapper .inr-icon {
  width: 13px;
  height: 13px;
}

.create-post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.create-post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.create-post-card-wrapper .card-follow {
  float: right;
}

.create-post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.create-post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.create-post-card-wrapper .card-footer {
  border: 0px !important;
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24);
}

.create-post-card-wrapper .card-footer .author {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.create-post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.create-post-card-wrapper .card-footer .edit-options {
  float: right;
}

.create-post-wrapper .complete-payment {
  position: fixed;
  left: 50%;
  bottom: 3%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  /* color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer; */
}

.create-post-wrapper h6 {
  color: var(--color-gray-800);
  font-size: var(--font-size);
}

.create-post-wrapper input,
textarea {
  font-size: var(--font-size);
}

.create-post-wrapper .image-label {
  border: 1px dotted var(--color-violet-400);
  background-color: var(--color-gray-200);
  color: var(--color-violet-400);
  padding: var(--spacing-24) 0;
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-8);
}

@media (min-width: 576px) {
  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}

@media (min-width: 768px) {
  /* Category modal */
  #selectPostCategoryModal .modal-body {
    min-width: 35vw;
  }
}

.create-reshare-post-card-wrapper:nth-child(1) {
  font-size: var(--font-size);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 0 !important;
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
}

.create-reshare-post-card-wrapper:nth-child(2) {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0 !important;
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
}

.create-reshare-post-card-wrapper:nth-child(2) .card-footer {
  font-size: var(--font-size);
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
  -webkit-backdrop-filter: blur(45.7534px);
          backdrop-filter: blur(45.7534px);
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.create-reshare-post-card-wrapper:nth-child(1) .card-body {
  /* padding-bottom: 0; */
  -webkit-filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.15));
}

.create-reshare-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer;
}

.create-reshare-post-card-wrapper .card-amount .currency-text {
  position: relative;
  left: -0.35rem;
}

.create-reshare-post-card-wrapper .currency-icon {
  width: 12px;
  height: 12px;
  position: relative;
  left: 0.12rem;
}

.create-reshare-post-card-wrapper .created-at {
  float: right !important;
  font-size: var(--font-size-small);
}

.navbar-violet-md {
  background: var(--color-violet-400);
  border-radius: 0 0 1rem 1rem;
  min-height: var(--spacing-64);
  padding-left: var(--spacing-24);
}

.navbar-violet-md .navbar-brand {
  color: var(--color-white);
  font-size: var(--font-size-big) !important;
  font-weight: 500 !important;
}

.settings-wrapper .section {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

.settings-wrapper .section-header {
  font-size: var(--font-size);
  font-weight: 600;
  padding: var(--spacing-4) 0 var(--spacing-8);
  /* color: #dc6535; */
}

.settings-wrapper .section:first-child .section-header {
  padding-top: var(--spacing-32);
}

.settings-wrapper .section-list {
  list-style: none;
  padding: 0;
}

.settings-wrapper .section-list li {
  padding: 0.7rem 0;
  align-items: center;
}

.settings-wrapper .section-list li .option-icon {
}

.settings-wrapper .section-list li .option-name {
  color: #000000;
  padding-left: 0;
  font-size: 14px;
}

.settings-wrapper .section-list li .option-open {
  height: 14px;
}

.scale-0-9 {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.scale-1-1 {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.scale-1-2 {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.scale-1-3 {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.scale-1-4 {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.scale-1-4 {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.scale-1-5 {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.settings-wrapper .row img:first-child {
  width: 22px;
  height: 22px;
  padding: 0px !important;
}

/* Start: Toggle switch colors */
/* Ref: https://stackoverflow.com/questions/64435290/how-can-i-change-the-checked-background-color-of-this-bootstrap-4-toggle-switc */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}
/* End: Toggle switch colors */

@media (max-width: 576px) {
  .settings-wrapper {
    padding-top: var(--spacing-16);
    background-color: var(--color-white);
  }
}

.change-pw-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.change-pw-wrapper .form-group {
  position: relative;
}

.change-pw-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.change-pw-wrapper .form-control,
.change-pw-wrapper .form-control:active,
.change-pw-wrapper .form-control:focus,
.change-pw-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
  box-shadow: none !important;
}

.pw-visibility-icon {
  width: 20px;
  width: 20px;
  position: absolute;
  top: 33px;
  right: 10px;
}

@media (max-width: 576px) {
}

.edit-profile-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.edit-profile-wrapper .upload-image {
  text-align: center;
  padding: var(--spacing-24) 0;
  position: relative;
}

.edit-profile-wrapper .upload-image .camera-icon-wrapper {
  position: absolute;
  bottom: 20%;
  left: 55%;
  text-align: center;
  background: var(--color-white);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.edit-profile-wrapper .upload-image .camera-icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.edit-profile-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.edit-profile-wrapper .form-control,
.edit-profile-wrapper .form-control:active,
.edit-profile-wrapper .form-control:focus,
.edit-profile-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
}

.edit-profile-wrapper textarea.form-control {
  resize: none;
}

.edit-profile-wrapper input:read-only {
  background-color: transparent;
}

.edit-profile-wrapper input[type="date"] {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 576px) {
}

.profile-page-wrapper {
  position: relative;
  top: -3.75vh;
  /* position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px; */
}

.profile-wrapper {
  position: relative;
  /* width: 100vw; */
  min-height: 25vh;
  left: 0px;
  top: -5px;
  background: linear-gradient(180deg, #6930db -49.99%, #ffb47e 208.18%);
  border-radius: 0px 0px 42px 42px;
  color: var(--color-white);
}

.profile-wrapper .display-picture .custom-image-text {
  border-radius: 20px !important;
}

.profile-wrapper .profile-info {
  text-align: center;
  align-items: center;
  padding-top: var(--spacing-12);
}

.profile-wrapper .profile-info a {
  color: var(--color-white);
  font-weight: 500;
}

.profile-wrapper .profile-bio {
  padding: var(--spacing-4) var(--spacing-16) 0;
  text-align: center;
  font-size: var(--font-size);
}

.profile-wrapper .btn-custom {
  background-color: var(--color-white);
  border-radius: var(--spacing-32);
  font-size: var(--font-size);
  font-weight: 500;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 15px -4px rgb(0 0 0 / 20%);
  position: relative;
  top: 2.25vh;
}

.profile-card {
  padding: var(--spacing-16);
}

.profile-bio {
  padding: var(--spacing-16) var(--spacing-8) var(--spacing-4);
}

.profile-bio .label {
  font-size: var(--font-size);
}

.profile-wrapper .icon-request-sent {
  width: 15px;
  height: 15px;
}

/* wallet code */
.wallet-wrapper a {
  color: rgba(0, 0, 0, 0.9);
}

.wallet-icon {
  width: 27px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

/* Private profile text */
.private-profile-text {
  text-align: center;
  position: relative;
  top: calc(15vh + 50px);
}

@media (max-width: 576px) {
  .posts-footer {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .posts-footer {
    display: flex;
  }
}

@media (min-width: 768px) {
  .profile-page-wrapper {
    position: relative;
    top: var(--spacing-24);
  }
}

.feeling-wrapper {
  padding-top: var(--spacing-24) !important;
}

.feeling-wrapper .filter-wrapper {
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-16);
  overflow-x: auto;
}

.feeling-wrapper .filter-wrapper .filter-icon {
  float: left;
}

.feeling-wrapper .filter-wrapper .filter-names {
  padding: 0 var(--spacing-12);
  overflow-x: scroll;
  white-space: nowrap;
  /* transition: visibility 0s linear 0.33s, opacity 0.33s linear; */
}

.show-filter-names {
  display: block;
  -webkit-animation: showFilter 0.35s 1;
          animation: showFilter 0.35s 1;
}

.hide-filter-names {
  display: none;
  -webkit-animation: hideFilter 0.35s 1;
          animation: hideFilter 0.35s 1;
}

@-webkit-keyframes showFilter {
  from {
    -webkit-transform: translateX(+200vw);
            transform: translateX(+200vw);
  }
  to {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
}

@keyframes showFilter {
  from {
    -webkit-transform: translateX(+200vw);
            transform: translateX(+200vw);
  }
  to {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
}

@-webkit-keyframes hideFilter {
  from {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  to {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@keyframes hideFilter {
  from {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  to {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

.feeling-wrapper .filter-wrapper .unselected {
  border: 1px solid #f4effd;
  border-radius: 6px;
}

.feeling-wrapper .filter-wrapper .selected-filter {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.feeling-wrapper .filter-wrapper .selected-filter-special {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-special);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.feeling-wrapper .scroll-to-top {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: var(--font-size);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  border-radius: 1.5rem;
  cursor: pointer;
}

.product-card .card {
  padding: var(--spacing-24);
  /* background: var(--color-orange-100); */
  border: 0px solid var(--color-violet-100);
  border-radius: var(--spacing-12);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-card .card .icon-document-download {
  width: var(--spacing-20);
  height: var(--spacing-20);
}

@media (max-width: 575.97px) {
  .scroll-to-top {
    top: calc(100vh - 16vh);
  }
}

@media (min-width: 576px) {
  .scroll-to-top {
    bottom: 3vh;
  }
}

@media (min-width: 992px) {
  .feeling-wrapper {
    padding: 0rem 3rem;
  }
}

.show-post-subheader {
  position: fixed;
  width: 100vw;
  padding: 36px var(--spacing-24) var(--spacing-12);
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  z-index: 9;
}

.show-post-subheader .go-back-icon {
  width: 14px;
  height: 14px;
}

.show-post-subheader .page-name {
  font-weight: 500;
  font-size: var(--font-size);
  padding-top: 2px;
}

.feedback-img {
  width: var(--spacing-24);
  height: var(--spacing-24);
  outline: cover;
  cursor: pointer;
}

.doctor-img {
  width: var(--spacing-64);
  height: var(--spacing-64);
  margin-right: var(--spacing-12);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-violet-400);
  padding: 2px;
}

.wallet-details-wrapper {
  position: relative;
  top: -3px;
}

.wallet-details-wrapper .page-header {
  font-size: var(--font-size);
  font-weight: 500;
}

/* Total amount section */
.wallet-details-wrapper .total-amount-section {
  position: relative;
  left: 0px;
  top: -5vh;
  min-height: 28vh;
  background: linear-gradient(180deg, #6930db -49.99%, #ffb47e 208.18%);
  border-radius: 0px 0px 42px 42px;
  color: var(--color-white);
}

.total-amount-section .balance-details {
  text-align: center;
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}

.total-amount-section .balance-details .balance-amount {
  align-items: center;
}

.total-amount-section .balance-details .balance-amount .currency-icon {
  width: 18px;
  height: 18px;
  position: relative;
  left: 4px;
}

.total-amount-section .balance-details .balance-amount .text {
  font-size: 24px;
  position: relative;
  top: 4px;
  padding-left: var(--spacing-4);
}

.total-amount-section .balance-details .balance-text {
  font-size: var(--font-size-bigger);
  margin-top: var(--spacing-8);
  font-weight: 500;
}

/* Enter passcode section */
.enter-passcode-section {
  text-align: center;
}

.enter-passcode-section .passcode-text {
  font-size: var(--font-size-bigger);
  margin-top: var(--spacing-64);
  margin-bottom: var(--spacing-64);
}

.enter-passcode-section .passcode-card {
  text-align: center;
}

.enter-passcode-section .passcode-input {
  font-size: var(--font-size-bigger);
  border-width: 0;
  border-bottom: 3px solid #d5d5d5;
  border-radius: 0;
  margin-left: var(--spacing-12);
  margin-right: var(--spacing-12);
  outline: none !important;
  max-width: 24vw;
  letter-spacing: var(--spacing-12);
  text-indent: var(--spacing-8);
  text-align: center;
}

.enter-passcode-section .passcode-input:focus {
  border-bottom: 2px solid var(--color-violet-400);
}

.enter-passcode-section .submit-passcode {
  margin-top: var(--spacing-64);
}

/* Extra code */
.wallet-details-wrapper .btn-custom {
  background-color: var(--color-white);
  border-radius: var(--spacing-32);
  font-size: var(--font-size);
  font-weight: 500;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 15px -4px rgb(0 0 0 / 20%);
  position: relative;
  top: 2.25vh;
}

.profile-card {
  padding: var(--spacing-16);
}

.profile-bio {
  padding: var(--spacing-16) var(--spacing-8) var(--spacing-4);
}

.profile-bio .label {
  font-size: var(--font-size);
}

.wallet-details-wrapper .icon-request-sent {
  width: 15px;
  height: 15px;
}

/* wallet code */
.wallet-wrapper a {
  color: rgba(0, 0, 0, 0.9);
}

.wallet-icon {
  width: 27px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

/* Private profile text */
.private-profile-text {
  text-align: center;
  position: relative;
  top: calc(15vh + 50px);
}

/* create wallet */
.create-wallet-wrapper {
  font-size: 13px;
  padding: 3rem 0;
  min-height: 100vh;
}

.create-wallet-wrapper .btn-next {
  font-size: 12px;
  border: 1px solid #1a1a2e;
  margin: 2rem 0;
}

/* duplicate wallet */
.duplicate-wallet-wrapper {
  font-size: 13px;
  padding: 3rem 0;
  min-height: 100vh;
}

.duplicate-wallet-wrapper .btn-next {
  font-size: 12px;
  border: 1px solid #1a1a2e;
  margin: 2rem 0;
}

/* wallet details */
.wallet-details-wrapper {
  font-size: 13px;
  padding: 2rem 0;
  min-height: 100vh;
}

.wallet-details-wrapper .btn-next {
  font-size: 12px;
  border: 1px solid #1a1a2e;
  margin: 2rem 0;
}

/* Transactions section */
.wallet-wrapper .transactions-section {
  margin: 0 var(--spacing-24);
}

.wallet-wrapper .transactions-section .all-transactions-text {
  text-align: center;
  font-size: var(--font-size);
  padding: 10px 0;
}

.wallet-wrapper .transactions-section .transaction-card {
  border: 2px solid var(--color-white-600);
  border-radius: var(--spacing-24);
  padding: var(--spacing-8) var(--spacing-8);
  height: 50vh;
  overflow-y: scroll;
}

.wallet-wrapper .transaction-card .transaction-date {
  font-size: smaller;
  color: rgba(0, 0, 0, 0.5);
}

.wallet-wrapper .transaction-card .transaction-name {
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
}

.wallet-wrapper .transaction-card .transaction-amount {
  font-size: 14px;
  padding-top: 5px;
}

.wallet-wrapper .transaction-card .currency-icon {
  width: 12px;
  height: 12px;
  position: relative;
  right: -2px;
}

.wallet-wrapper .transaction-card .credit {
  color: #12b118;
}

.wallet-wrapper .transaction-card .debit {
  color: #b11212;
}

.wallet-wrapper .transaction-card .transaction-divider {
  margin: 7px 0px !important;
}

@media (max-width: 576px) {
  .posts-footer {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .posts-footer {
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .wallet-wrapper {
    margin-top: -1.5rem;
  }
}

@media (min-width: 768px) {
  .wallet-wrapper {
    position: relative !important;
    top: var(--spacing-24) !important;
  }
}

.navbar-violet-md {
  background: var(--color-violet-400);
  border-radius: 0 0 1rem 1rem;
  min-height: var(--spacing-48);
  padding-left: var(--spacing-24);
}

.navbar-violet-md .go-back-icon {
  width: 18px;
  height: 18px;
}

.navbar-violet-md .navbar-brand {
  color: var(--color-white);
  font-size: var(--font-size-big) !important;
  font-weight: 500 !important;
}

.settings-wrapper .section {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

.settings-wrapper .section-header {
  font-size: var(--font-size);
  font-weight: 600;
  padding: var(--spacing-4) 0 var(--spacing-8);
  /* color: #dc6535; */
}

.settings-wrapper .section:first-child .section-header {
  padding-top: var(--spacing-32);
}

.settings-wrapper .section-list {
  list-style: none;
  padding: 0;
}

.settings-wrapper .section-list li {
  padding: 0.7rem 0;
  align-items: center;
}

.settings-wrapper .section-list li .option-icon {
}

.settings-wrapper .section-list li .option-name {
  color: #000000;
  padding-left: 0;
  font-size: 14px;
}

.settings-wrapper .section-list li .option-open {
  height: 14px;
}

.scale-0-9 {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.scale-1-1 {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.scale-1-2 {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.scale-1-3 {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.scale-1-4 {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.scale-1-4 {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.scale-1-5 {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.settings-wrapper .row img:first-child {
  width: 22px;
  height: 22px;
  padding: 0px !important;
}

/* Start: Toggle switch colors */
/* Ref: https://stackoverflow.com/questions/64435290/how-can-i-change-the-checked-background-color-of-this-bootstrap-4-toggle-switc */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}
/* End: Toggle switch colors */

@media (max-width: 576px) {
  .navbar-violet-md .go-back-icon {
    width: 20px;
    height: 20px;
  }

  .settings-wrapper {
    padding-top: var(--spacing-16);
    background-color: var(--color-white);
  }
}

/* header css */
a.chat-active {
  background-color: var(--color-white);
  border-radius: 50% 50% 0 0;
  padding: 0.85rem !important;
  position: relative;
  top: 3px;
  /* -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 10px, transparent 20px, black 21px); */
}

a.chat-active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  left: -25px;
  height: 35px;
  width: 25px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 9px 0 0 var(--color-white);
  z-index: -99;
}

a.chat-active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  right: -25px;
  height: 35px;
  width: 25px;
  border-bottom-left-radius: 20px;
  box-shadow: -5px 9px 0 0 var(--color-white);
  z-index: -99;
}

/* body */
.conversation-list {
  list-style-type: none;
}

.conversation-list li {
  font-size: var(--font-size);
  transition: all 0.35s;
  cursor: pointer;
}

.conversation-list li:hover {
  background-color: rgba(200, 200, 200, 0.5);
}

.conversation-list li.active {
  background-color: rgba(200, 200, 200, 0.5);
}

.new-chat {
  position: fixed;
  bottom: 4vh;
  right: 6vw;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-violet-400);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.new-chat .icon-chat {
  width: 22px;
  height: 22px;
  position: relative;
  top: var(--spacing-12);
  left: var(--spacing-1);
}

.request-note {
  position: fixed;
  bottom: 0px;
  background-color: var(--color-violet-100);
  margin: 0 auto;
  font-size: var(--font-size);
  padding: var(--spacing-12);
  text-align: center;
  left: 0;
  -webkit-transform: translate(calc(50vw - 50%));
          transform: translate(calc(50vw - 50%));
}

.chat-subheader {
  position: fixed;
  width: 100vw;
  padding: 36px var(--spacing-24) var(--spacing-12);
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  z-index: 9;
}

/* chat window */
.chat-window {
  margin: 0px auto !important;
  position: static;
  bottom: 0px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 4px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.chat-window .sender {
  border-radius: 0px 12px 12px;
  word-break: break-word;
  color: var(--color-black-400);
  background-color: var(--color-pink-100);
  padding: var(--spacing-8) var(--spacing-8) var(--spacing-24);
}

.chat-window .receiver {
  margin-left: auto;
  border-radius: 12px 12px 0px;
  word-break: break-word;
  color: var(--color-black-400);
  border: 1px solid var(--color-pink-400);
  padding: var(--spacing-8) var(--spacing-8) var(--spacing-24);
  word-break: break-word;
}

/* chat window footer */
.chat-window-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.chat-window-footer .form-control {
  font-size: var(--font-size);
  border: 1px solid var(--color-violet-100);
  border-radius: var(--spacing-24);
  background-color: var(--color-violet-100);
  text-indent: var(--spacing-4);
  padding-top: var(--spacing-12);
  box-shadow: none !important;
  resize: none;
}

.chat-window-footer .form-control:focus {
  border: 1px solid var(--color-violet-100);
}

.chat-window-footer .input-group-append {
  border: 0px solid var(--color-violet-100);
  border-radius: 0 var(--spacing-24) var(--spacing-24) 0;
  background-color: var(--color-violet-100);
  padding: var(--spacing-12);
  cursor: pointer;
}

.chat-window-dp {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid (20, 20, 20, 0.45) !important;
  object-fit: contain;
}

@media (max-width: 576px) {
  .chat-window {
    padding-bottom: var(--spacing-64);
  }

  .chat-window .sender {
    max-width: calc(100% / 1.5);
  }

  .chat-window .receiver {
    max-width: calc(100% / 1.5);
    margin-right: var(--spacing-8);
  }

  .chat-window-footer {
    width: 100%;
  }

  .chat-window-footer .reply-box {
    /* width: 100%; */
    padding: var(--spacing-8);
    resize: none;
    outline: none;
  }
}

@media (min-width: 576px) {
  .chat-window-header {
    display: none;
  }

  .chat-window {
    width: calc(100vw - 18.25rem);
    max-width: 50vw;
    height: calc(100vh - 11rem);
    position: fixed;
    top: 13vh;
  }

  .mob-single-chat-window .chat-window .sender {
    max-width: calc(100% / 2);
  }

  .mob-single-chat-window .chat-window .receiver {
    max-width: calc(100% / 2);
  }

  .chat-window-footer {
    bottom: 0px;
    max-width: 50vw;
  }
}

.custom-image {
  width: var(--spacing-40);
  height: var(--spacing-40);
  border-radius: 13%;
  object-fit: cover;
}

.custom-image-text {
  width: var(--spacing-40);
  height: var(--spacing-40);
  border-radius: 13%;
  color: var(--color-white);
  background-color: var(--color-violet-400);
  font-size: var(--font-size-big);
  padding-top: var(--spacing-8);
  text-align: center;
}

.custom-image.-sm {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.custom-image-text.-sm {
  width: var(--spacing-24);
  height: var(--spacing-24);
  font-size: var(--font-size);
  padding-top: var(--spacing-2);
}

.custom-image.-lg {
  width: var(--spacing-64);
  height: var(--spacing-64);
}

.custom-image-text.-lg {
  width: var(--spacing-64);
  height: var(--spacing-64);
  font-size: var(--font-size-bigger);
  padding-top: var(--spacing-16);
}

#reportPostModal .title {
  padding: var(--spacing-12);
  margin-bottom: 0;
}

#reportPostModal .post-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-12);
}

#reportPostModal .other-category-box {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

#reportPostModal .other-category-box textarea {
  border: none;
  resize: none;
  background-color: var(--color-violet-100);
  padding-left: 0;
  padding-right: 0;
  text-indent: var(--spacing-8);
}

.bottom-sm-padding {
  /* padding-top: 25px;
  padding-bottom: 25px; */
}

.navbar-bottom {
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 99;
}

.footer-wrapper {
  position: relative;
  width: 100%;
  background-color: var(--color-gray-100);
}

/* Ref: https://itnext.io/how-to-make-a-fancy-inverted-border-radius-in-css-5db048a53f95 */
.footer-wrapper::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 43px;
  left: 0px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 35px;
  box-shadow: 0 18px 0 0 var(--color-gray-100);
}

.footer-wrapper::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 43px;
  right: 0px;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 35px;
  box-shadow: 0px 18px 0 0 var(--color-gray-100);
}

.navbar-bottom .nav-item {
  border-bottom: 3px solid transparent;
}

.navbar-bottom .nav-item-active {
  border-bottom: 3px solid #fff;
}

.navbar-bottom .nav-link a {
  color: #000;
  text-decoration: none;
}

.navbar-bottom .nav-link a:hover {
  text-decoration: none;
}

.navbar-bottom .create-post {
  position: absolute;
  top: -17px;
  left: 43.75%;
  background-color: var(--color-violet-400);
  border-radius: 50%;
  width: 53px;
  height: 53px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.navbar-bottom .create-post .plus-c-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}

.navbar-bottom .home-icon {
  width: 22px;
  height: 22px;
}

.navbar-bottom .profile-icon {
  width: 22px;
  height: 22px;
}

.audio-player-wrapper {
  padding: var(--spacing-16) var(--spacing-12) 0;
}

.audio-player-wrapper .audio-btn {
  text-align: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: none;
  margin: 0px auto;
}

.audio-player-wrapper .audio-btn .btn-play {
  width: 4vw;
  position: relative;
  top: 4px;
  left: 1px;
  transition: all 0.25s ease-in-out;
}

.audio-player-wrapper .audio-btn .btn-pause {
  width: 3vw;
  position: relative;
  top: 4px;
  transition: all 0.25s ease-in-out;
}

.audio-player-wrapper .audio-time .current {
  font-size: var(--font-size-small);
}

.audio-player-wrapper .audio-time .duration {
  font-size: var(--font-size-small);
  float: right;
}

.audio-player-wrapper .range {
  width: 100%;
  height: 2px;
  border: 1px solid #000000;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  top: -7px;
  -webkit-appearance: none;
}

.audio-player-wrapper .range::-webkit-slider-thumb {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--color-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  position: relative;
  top: -1px;
  -webkit-appearance: none;
  z-index: 99;
}

.audio-player-wrapper .progress {
  height: 2px;
  position: relative;
  top: -15px;
  background: #2b2b2b !important;
}

.audio-player-wrapper .progress-bar {
  background-color: var(--color-violet-400) !important;
}

.change-pc-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.change-pc-wrapper .form-group {
  position: relative;
  text-align: center;
  margin: var(--spacing-48) auto;
}

.change-pc-wrapper .form-label {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.change-pc-wrapper .form-control,
.change-pc-wrapper .form-control:active,
.change-pc-wrapper .form-control:focus,
.change-pc-wrapper .form-control:hover {
  /* padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 200px;
  min-width: 200px; */

  border: none;
  background: repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey 15vw,
      transparent 0,
      transparent 20vw
    )
    0 100%/100% 2px no-repeat;
  letter-spacing: 0.5ch;

  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
  box-shadow: none !important;
}

.change-pc-wrapper .otp-message {
  padding: var(--spacing-32) 0;
}

.pw-visibility-icon {
  width: 20px;
  width: 20px;
  position: absolute;
  top: 33px;
  right: 10px;
}

.wallet-recharge-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.wallet-recharge-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

/* Stripe card */
.CardNumberField-input-wrapper {
  width: 300px !important;
}

.CardNumberField-input-wrapper .InputContainer .InputElement {
  width: 200px !important;
}

.CardBrandIcon-container.is-hidden {
  width: 50px !important;
}

.wallet-withdraw-wrapper {
  padding: var(--spacing-48) var(--spacing-16);
  text-align: left;
}

.wallet-withdraw-wrapper .withdraw-danger-alert {
  color: var(--color-red-400);
  font-size: var(--font-size-smaller);
  margin-top: var(--spacing-4);
}

.wallet-withdraw-wrapper .withdraw-success-alert {
  margin-top: var(--spacing-4);
  color: var(--color-green-600);
  font-size: var(--font-size-smaller);
}

.wallet-withdraw-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.wallet-withdraw-wrapper .form-control:focus {
  box-shadow: none;
}

/*  */
.wallet-withdraw-wrapper .step-header {
  text-align: center;
}

.wallet-withdraw-wrapper .step-header .header-text {
  margin: var(--spacing-32) 0;
}

.wallet-withdraw-wrapper .invoice-table {
  border: 2px solid #d5d5d5;
  border-radius: var(--spacing-24);
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}

.wallet-withdraw-wrapper .invoice-table .invoice-table-section {
  padding: var(--spacing-16) var(--spacing-16);
  font-size: var(--font-size);
}

.wallet-withdraw-wrapper .invoice-table .invoice-table-divider {
  margin: 0;
}

.wallet-withdraw-wrapper .invoice-table .col-header {
  padding-bottom: var(--spacing-12);
}

.wallet-withdraw-wrapper .invoice-table .font-bold {
  font-weight: 500;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--color-white-600);
  position: relative;
}

.btn-disabled .info {
  width: 17px;
  height: 17px;
}

.menu-list .menu-item {
  cursor: pointer;
}

#tooltip {
  display: inline-block;
  background: #ffffff;
  color: var(--color-violet-400);
  font-weight: bold;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid var(--color-white-600);
  top: 10px !important;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  border-top: 1px solid var(--color-white-600);
  border-left: 1px solid var(--color-white-600);
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}

@media (max-width: 767.98px) {
  #menuOptionsModal .user-list {
    max-height: 50vh;
    overflow: scroll;
  }

  #menuOptionsModal .modal-body {
    font-size: var(--font-size);
    padding-left: var(--spacing-12) !important;
    min-height: 29vh !important;
    max-height: 50vh !important;
  }

  #menuOptionsModal .chat-user {
    padding: var(--spacing-4) 0;
  }

  #menuOptionsModal .media-body {
    align-items: baseline;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767.98px) {
  /* Modal css */
  .modal.fade .modal-dialog {
    margin: 0px;
    border-radius: 25px;
    -webkit-transform: translate3d(0, calc(100vh - 29vh), 0) !important;
            transform: translate3d(0, calc(100vh - 29vh), 0) !important;
  }

  .modal.fade .modal-content {
    border-radius: 25px 25px 0 0 !important;
  }

  .modal-body .menu-list {
    list-style-type: none;
    padding: 0;
  }

  .modal-body .menu-list .menu-item {
    padding: var(--spacing-8) var(--spacing-12);
  }

  #shareOptionsModal .modal-dialog {
    -webkit-transform: translate3d(0, calc(100vh - 14vh), 0) !important;
            transform: translate3d(0, calc(100vh - 14vh), 0) !important;
  }

  #shareOptionsModal .modal-body {
    min-height: 17vh !important;
    max-height: 70vh !important;
  }
}

@media (min-width: 768px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }

  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal.fade .modal-dialog {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }

  .modal.fade .modal-content {
    border-radius: var(--spacing-32) !important;
    min-width: 100%;
  }

  .modal-body .menu-list {
    list-style-type: none;
    padding: 0;
  }

  .modal-body .menu-list .menu-item {
    padding: var(--spacing-12) var(--spacing-128);
  }

  #shareOptionsModal .modal-body {
    min-height: 10vh !important;
    /* max-height: 70vh !important; */
  }
}

.invite-friends-wrapper {
  margin-top: var(--spacing-128);
}

.invite-friends-wrapper .lg-input {
  background-color: var(--color-violet-100);
  border: 0;
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--spacing-24);
  min-width: 30vw;
  text-align: center;
  font-size: var(--font-size);
}

.show-transaction-wrapper {
  padding: var(--spacing-48) var(--spacing-16);
  text-align: left;
}

.show-transaction-wrapper .invoice-table {
  border: 2px solid #d5d5d5;
  border-radius: var(--spacing-24);
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}

.show-transaction-wrapper .invoice-table .invoice-table-section {
  padding: var(--spacing-12) var(--spacing-16);
  font-size: var(--font-size);
}

.show-transaction-wrapper .invoice-table .invoice-table-divider {
  margin: 0;
}

.show-transaction-wrapper .transaction-details {
  text-align: center;
}

.show-transaction-wrapper .transaction-details .currency-icon {
  width: 19px;
  height: 19px;
  position: relative;
  left: 3px;
}

.show-transaction-wrapper .transaction-details .transaction-amount {
  margin-bottom: 0;
}

.show-transaction-wrapper .transaction-details small {
  text-transform: capitalize;
  font-size: var(--font-size-smaller);
}

.show-transaction-wrapper .transaction-details .debit {
  color: #b11212;
}

.show-transaction-wrapper .transaction-details .pending {
  color: #ffe427;
}

.show-transaction-wrapper .transaction-details .credit {
  color: #12b118;
}

.show-transaction-wrapper .transaction-note {
  font-size: var(--font-size-small);
  text-align: center;
}

@media (min-width: 768px) {
  .show-transaction-wrapper {
    margin-top: var(--spacing-64);
    padding-top: var(--spacing-64);
  }
}

.send-alerts-wrapper {
  padding: var(--spacing-64) var(--spacing-32) 0;
}

.send-alerts-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.send-alerts-wrapper .form-control,
.send-alerts-wrapper .form-control:active,
.send-alerts-wrapper .form-control:focus,
.send-alerts-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
}

.send-alerts-wrapper .created-at {
  color: #2b2b2b;
  font-size: var(--font-size-smaller);
}

@media (max-width: 576px) {
  .send-alerts-wrapper {
    padding: var(--spacing-48) var(--spacing-16) 0;
  }
}

.profile-graphs-container {
  width: 100vw;
  text-align: center;
  margin: 0px auto !important;
}

@media (max-width: 767.97px) {
  .profile-graphs-container {
    /* display: none; */
  }
}

@media (min-width: 768.97px) {
  .profile-graphs-container .graphs {
    position: relative;
    left: 10vw;
    width: 30vw;
    top: var(--spacing-48);
  }
}

.linechart-wrapper {
  position: relative;
  overflow: hidden;
}

.linechart-area-wrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.linechart-area-wrapper {
  height: 500px !important;
}

@media (max-width: 767.98px) {
  .linechart-area-wrapper {
    width: 500px;
    height: 200px !important;
    overflow-x: scroll !important;
  }
}

.emotion-score-wrapper .username {
  font-size: var(--font-size-small);
  color: var(--color-violet-400);
}

.emotion-score-wrapper .emotion-score {
  font-size: var(--font-size-bigger);
  margin: var(--spacing-8) 0;
}

.emotion-score-wrapper .question-mark {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
  width: var(--spacing-24);
  height: var(--spacing-24);
  border-radius: 50% !important;
  position: relative;
}

.emotion-score-wrapper .question-mark span {
  position: absolute;
  top: 0;
  left: 7px;
}

.emotion-score-wrapper .score-description {
  font-size: var(--font-size-smaller);
}

.faq-wrapper {
  margin-top: var(--spacing-64);
}

.question {
  cursor: pointer;
  color: var(--color-violet-700);
}

.answers {
  color: var(--color-violet-100);
  margin: 0 0 10px 0;
  max-height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: 0.7s ease;
}

.plus {
  font-size: var(--spacing-24);
  float: right;
}

.minus {
  display: none;
  font-size: var(--spacing-24);
  float: right;
}

.questions {
  display: none;
}

.questions:checked ~ .answers {
  max-height: 50vh;
  opacity: 1;
}

.cookie-policy-wrapper {
  margin-top: var(--spacing-80);
}

.privacy-policy-wrapper {
  margin-top: var(--spacing-128);
  margin-bottom: var(--spacing-80);
}

.privacy-policy-wrapper .pp-header {
  color: var(--color-violet-800);
}

.privacy-policy-wrapper {
  margin-top: var(--spacing-128);
  margin-bottom: var(--spacing-80);
}

.privacy-policy-wrapper .pp-header {
  color: var(--color-violet-800);
}

.tnc-wrapper {
  margin-top: var(--spacing-128);
  margin-bottom: var(--spacing-80);
}

.tnc-wrapper .pp-header {
  color: var(--color-violet-800);
}

.assign-to-doctor-wrapper .input-group-append span {
  color: var(--color-white);
  background-color: var(--color-violet-400) !important;
  border: 0px;
}

.request-card {
  background-color: var(--color-white) !important;
  border: 0px solid var(--color-violet-100) !important;
  border-radius: var(--spacing-12) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}




.choose-certificate-wrapper .card {
  background-color: var(--color-white);
  border: 0px;
  border-radius: var(--spacing-12);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.create-doctor-prescription select {
  box-shadow: none !important;
}


.privacy-policy-wrapper {
  margin-top: var(--spacing-128);
  margin-bottom: var(--spacing-80);
}

.privacy-policy-wrapper .pp-header {
  color: var(--color-violet-800);
}

.icon-heart {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.icon-heart.active {
  -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.scaleInImgFollow {
  -webkit-animation: scaleIn 0.75s 2;
          animation: scaleIn 0.75s 2;
}

.scaleInImgUnfollow {
  -webkit-animation: scaleIn 0.75s 1;
          animation: scaleIn 0.75s 1;
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }

  50% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }
}

@keyframes scaleIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }

  50% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }
}

.follow-blog {
  text-align: center;
  cursor: pointer;
  padding-top: var(--spacing-3);
}

.follow-blog .follower-length {
  color: #f42222;
  position: relative;
  top: -7px;
  left: var(--spacing-12);
  font-size: var(--font-size-bigger);
  -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.blogs-wrapper {
  margin-top: var(--spacing-32);
  padding: var(--spacing-128);
}

#navbar-brand-logo-blog {
  width: 9rem !important;
}

.blog-footer a {
  text-decoration: none;
  color: #fff;
}

.blog-footer a:hover {
  color: #808080;
}

.blog-header .navbar-nav a {
  margin-top: 0.5rem;
}

.blogs-wrapper .blog-img {
  border-radius: var(--spacing-32);
  width: 100% !important;
  object-fit: cover;
}

.blogs-wrapper .btn-violet-outline {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;
  color: black !important;
  border: none;
}

.blogs-wrapper .blog-category {
  border-radius: var(--spacing-16);
  font-family: "Quicksand", sans-serif !important;
  font-size: var(--font-size-bigger);
  font-weight: bolder;
}

.blogs-wrapper .blog-header {
  cursor: pointer;
  font-weight: bolder;
  font-family: "Quicksand", sans-serif !important;

  /* font-size: 2rem !important; */
}

.blogs-wrapper .card {
  border: 0 solid;
  border-radius: var(--spacing-16);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  transition: -webkit-transform 100ms ease-in;
  transition: transform 100ms ease-in;
  transition: transform 100ms ease-in, -webkit-transform 100ms ease-in;
}

.blogs-wrapper .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.blogs-wrapper .card .blog-img {
  max-height: 17rem !important;
  object-fit: cover;
  border-radius: var(--spacing-16) var(--spacing-16) 0 0;
}

.blog-footer-section-wrapper {
  width: 100%;
  margin-top: var(--spacing-208);
  position: static;
  flex-shrink: 0;
}

.blog-footer-background-with-content {
  position: relative;
  z-index: 1;
}

.blog-footer-background-desktop {
  width: 100vw;
  height: 100%;
}

.blog-footer-links {
  line-height: var(--spacing-32);
}

.blog-footer-links:hover {
  color: var(--color-white) !important;
}

.blog-footer .company-name {
  font-size: var(--font-size-small);
  margin-top: var(--spacing-12);
}

.blog-footer .follow-us {
  font-size: var(--font-size-bigger);
}

.blog-footer .social-icon {
  padding-left: var(--spacing-24);
}

.blog-footer .social-icon img {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.blog-footer {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: left;
  top: 25%;
  left: 15%;
  width: 35vw;
}

.blog-footer .right-wrapper {
  text-align: right;
}

#blog-cta {
  color: #fff !important;
  margin-left: 35vw;
  margin-right: 35vw;
}

.navbar-brand-logo {
  padding-top: var(--spacing-24) !important;
}

.blogs-wrapper .col-3 .d-flex {
  position: -webkit-sticky;
  position: sticky;
  top: 8%;
}

.blogs-wrapper .row .col-sm-4 .card {
  align-self: center !important;
}

.badge-category {
  position: absolute;
  left: 4%;
  top: 6%;
  width: 3rem;
}

/* Progress bar */
#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 14%;
  left: 0;
}

#progressBar {
  background: linear-gradient(to left, #7c3bff, #7c3bff);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}

.footer-section-mobile-wrapper {
  width: 100%;
  position: relative;
}

.mobile .blog-footer-background-mobile {
  margin-top: 9rem;
  height: 75vh;
  width: 100vw;
}

.mobile .overlay-footer-links,
.company-info,
.overlay-footer-links-follow {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: center;
  word-spacing: 0.1rem;
  bottom: 60%;
}

.mobile .blog-footer-links {
  color: #fff;
  text-decoration: none;
}

.mobile .overlay-footer-links {
  bottom: 30%;
}

.company-info {
  top: 70%;
}

@media only screen and (max-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  #progressBarContainer {
    top: 6%;
  }
}

@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) {
  .blog-header {
    margin-top: 0;
    margin-bottom: 0.4rem !important;
  }

  #progressBarContainer {
    top: 6.8%;
  }

  .blog-footer-background-desktop {
    height: 40vh;
  }

  .col-4 .blog-footer {
    margin-left: -2rem;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  #progressBarContainer {
    top: 10.5%;
  }
}

@media only screen and (max-device-width: 320px) {
  #navbar-brand-logo-blog {
    margin-left: -0.1rem !important;
  }

  .mobile .blog-footer-background-mobile {
    margin-top: 10rem;
    height: 90vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-96) !important;
  }

  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}

@media only screen and (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .blog-nav-options {
    margin-top: -0.7rem;
  }

  .blog-nav-options .btn-violet-outline {
    margin-right: 8rem !important;
  }

  .blog-nav-options .btn-violet-news {
    margin-left: 12rem !important;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media (min-width: 768px) {
  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}

.editor-container {
  border: 1px solid black;
  min-height: "6em";
  cursor: "text";
}

/* trade range */
.range {
  height: var(--spacing-8);
  border: 1px solid var(--color-violet-400);
  background: var(--color-violet-400);
  border-radius: 25px;
  position: relative;
  top: -8px;
  -webkit-appearance: none;
}

.range::-webkit-slider-thumb {
  width: var(--spacing-16);
  height: var(--spacing-16);
  border-radius: 50%;
  background: var(--color-violet-400);
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35); */
  cursor: pointer;
  position: relative;
  top: -1px;
  -webkit-appearance: none;
  z-index: 99;
}

.edit-vc .vc-icon {
  width: var(--spacing-40) !important;
  height: var(--spacing-40) !important;
}

.clinics-wrapper .card-img-top {
  width: var(--spacing-64);
  height: var(--spacing-64);
  border-radius: 50%;
  border: var(--spacing-2) solid var(--color-violet-400);
  object-fit: cover;
}

.create-doctor-prescription select {
  box-shadow: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.question_box {
}

.comment_box {
}

